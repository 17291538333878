import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './registerServiceWorker'

import 'bootstrap'
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/app.css'
import '@/assets/styles/page.css'
import '@/assets/fonts/Ubuntu/ubuntu.css'
import '@/assets/styles/icofont.min.css'
import '@/assets/styles/fontawesome_all.css'

createApp(App).use(router).mount('#app')
