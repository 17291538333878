import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nuez',
    name: 'Nuez',
    component: () => import('@/views/NUEZ.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/nuezSettings.vue')
  },
  {
    path: '/gui',
    name: 'GUI',
    component: () => import('@/views/nuezGUI.vue')
  },
  {
    path: '/mongodb',
    name: 'MongoDB',
    component: () => import('@/views/nuezMongoDB.vue')
  },
  {
    path: '/other',
    name: 'Other',
    component: () => import('@/views/Other.vue')
  },
  {
    path: '/older',
    name: 'Older',
    component: () => import('@/views/Older.vue')
  },
  {
    path: '/hidden',
    name: 'Hidden',
    component: () => import('@/views/Hidden.vue')
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
