<template>
<div class="home antialiased min-h-screen bg-gray-100 dark:bg-gray-900 welcome">
  <div id="app">
    <div id="mainlayout">
        <div class="col container container-fluid animation">
          <div class="constructionPage pt-5">
              <div class="character">
                  <div class="chair"></div>
                  <div class="leftshoe"></div>
                  <div class="rightshoe"></div>
                  <div class="legs"></div>
                  <div class="torso404">
                      <div class="corpus"></div>
                      <div class="leftarm"></div>
                      <div class="rightarm"></div>
                      <div class="head">
                          <div class="eyes"></div>
                      </div>
                  </div>
                  <div class="laptop"></div>
                  <a type="button" @click="$router.push('/hidden')"> <div class="logo"></div> </a>
              </div>
          </div>
        </div>

        <div class="wrapper content d-flex my-auto">
            <div class="justify-content-around me-1" id="mainbuttons">
              <div class="flex-column px-5 wrapper">
                <p :class="['text-center', nomongo?'text-visible':'text-hide']">mongo</p>
                <div class="container-fluid d-flex col center" >
                  <button type="button" class="pg_btns text-center shadow-lg pt-1" id="mobile" @click="$router.push('/nuez')" @mouseenter="nomongo=true" @mouseleave="nomongo=false">
                    <img :src="comingsoon" class="img img-fluid" id="disclaimer" style="opacity:.4" />
                  </button>
                </div>
              </div>
              <div class="flex-column mx-5 wrapper">
                <p  :class="['text-center', noonion?'text-visible':'text-hide']">onion</p>
                <div class="container-fluid d-flex col center" >
                  <button type="button" class="pg_btns text-center shadow-lg pt-1" id="desktop" @click="$router.push('/')" @mouseenter="noonion=true" @mouseleave="noonion=false">
                    <img :src="construct" class="img img-fluid" id="construct" />
                  </button>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>

</template>

<script>
import Coming from '@/assets/resized/beta.png'
import Construct from '@/assets/resized/construct.png'

export default {
  name: 'Home',
  components: { },
  data(){ return { comingsoon: Coming, construct: Construct, noonion: false, nomongo: false } }
}

</script>
<style scoped>
  p {
    margin:auto;
    font-size:2.3vw;
    color:rgba(255,255,255,.025)
  }

  .text-hide {
    opacity: 0;
  }

  .text-visible {
    opacity: 1;
    transition: opacity 1s;
  }

  @media only screen and (max-width: 6000px) {

    #mainlayout {
      display: flex;
      flex: row;
      justify-content: around;
    }

    #mainbuttons {
      display:flex;
      flex:row;
      justify-content: between;
      width: 45vw;
    }

    .character {
      left:   50%;
      top:    7.5%;
      margin-left: -175px;
    }

    .pg_btns {
      position: relative;
      width: 45vh;
      height: 45vh;
      color: rgba(0, 0, 0, 0);
      margin: 3% 2.5%;
    }
  }
  @media only screen and (max-width: 1920px) {

    #mainlayout {
      display: flex;
      flex: row;
    }

    #mainbuttons {
      display:flex;
      flex:row;
      justify-content: between;
      width: 45vw;
    }

    .character {
      left:   50%;
      top:    20%;
      margin-left: -125px;
      transform: scale(.85);
      -moz-transform: scale(0.85);
    }

    .pg_btns {
      position: relative;
      width: 40vh;
      height: 40vh;
      color: rgba(0, 0, 0, 0);
      margin: 3% 2.5%;
    }

  }
  @media only screen and (max-width: 1080px) {

    #mainlayout {
      display: flex;
      flex: row;
    }

    #mainbuttons {
      display:flex;
      flex:row;
      justify-content: between;
      width: 50vw;
    }

    .character {
      left:   50%;
      top:    35%;
      margin-left: -125px;
      transform: scale(.7);
      -moz-transform: scale(0.7);
    }

    .pg_btns {
      width: 20vh;
      height: 20vh;
      color: rgba(0, 0, 0, 0);
      margin-left: 5%;
    }
  }

  @media only screen and (max-width: 720px) {

    #mainlayout {
      display: flex;
      flex: column;
    }

    .character {
      top: 72.5%;
      margin-right: 50%;
      margin-left: 50%;
      transform: scale(.575);
      -moz-transform: scale(0.575);
    }

    .content {
      display: flex;
      flex-direction: row;
    }

    #mainbuttons {
      display:relative;
      position:fixed;
      top: 10%;
      left: 20%;
      right: 20%;
      width: 60%;
      align:center;
    }

    .pg_btns {
      display: flex;
      width: 17.5vh;
      height: 17.5vh;
      color: rgba(0, 0, 0, 0);
      margin: 5% auto 15% auto;
    }
  }


  @media only screen and (max-width: 540px) {
    
    #mainlayout {
      display: flex;
      flex: column;
      justify-content: center;
    }

    #mainbuttons {
      display:block;
      position: fixed;
      top: 2.5%;
      height: 40%;
      left: 20%;
      right: 20%;
      width: 60%;
      align:center;
    }

    .character {
      margin-left: 45%;
      margin-right: 45%;
      top:    90%;
      transform: scale(.45);
      -moz-transform: scale(0.45);
    }

    .pg_btns {
      display: flex;
      width: 15vh;
      height: 15vh;
      color: rgba(0, 0, 0, 0);
      margin: 5% auto 15% auto;
    }
  }

  @media (orientation: landscape){
    body { flex-direction: row; }
  }

  @media (orientation: portrait){
    body { 
      flex-direction: column; 
      align-content: center;  
    }
  }

</style>